/**jshint esversion: 6 */
import './_foundation';
import {nanoid} from 'nanoid';
import random from "lodash/random";
import isEmpty from "lodash/isEmpty";
import toString from "lodash/toString";

/** current-device*/
import device from "current-device";
const is_mobile = () => device.mobile();

/** Fancybox*/
import { Fancybox } from "@fancyapps/ui";
Fancybox.bind(".wp-block-gallery .blocks-gallery-item a, .fcy-popup, .fcy-gallery a", {
    groupAll: true, // Group all items
});

/** Create deferred YT object */
// const YTdeferred = $.Deferred();
// window.onYouTubeIframeAPIReady = function () {
//     YTdeferred.resolve(window.YT);
// };

/** jquery multiselect */
require("@nobleclem/jquery-multiselect");

/** jquery load */
$(() => {

    /** */
    let province = $('select[name="thanhpho"]');
    province.on('change', function(e) {
        $.post(BASE_URL + 'page/ajax_district', { province_id: e.target.value}, function (d) {
            let district = $('select[name="quanhuyen"]');
            if (district.length > 0) {
                district.html(d);
            }
        });
    });

    /** */
    $(document).on('click','input[name="payment_method"]',function(e) {
        let class_open = 'payment_method_' + $(this).attr('id');
        $('.payment_box').hide();
        $('.' + class_open).show();
    });

    /** */
    $(document).on('click','.delete_cart',function(e) {
        e.preventDefault();

        let rowid = $( this ).attr('id');
        let details_giohang_tbl = $('.details-giohang-tbl');
        $.ajax({
            type: 'POST',
            url: BASE_URL + 'page/ajax_delete_cart',
            data:{
                'rowid': rowid,
            },
            beforeSend: function () {
                details_giohang_tbl.addClass('ajax-loading');
            },
            error: function () {
                alert("Request Failed.");
                details_giohang_tbl.removeClass('ajax-loading');
            },
            success: function (data) {
                details_giohang_tbl.removeClass('ajax-loading');
                redirect();
            }
        });
    });

    /** */
    $(document).on('click','button[name="update_cart"]',function(e) {
        e.preventDefault();
        let ids = [];
        let values = [];

        $('input[name="number_qty_sp"]').each(function () {
            ids.push(this.id);
            values.push(this.value);
        });

        let details_giohang_tbl = $('.details-giohang-tbl');
        $.ajax({
            type: 'POST',
            url: BASE_URL + 'page/ajax_update_cart',
            data:{
                'rowids': ids,
                'values': values,
            },
            beforeSend: function () {
                details_giohang_tbl.addClass('ajax-loading');
            },
            error: function () {
                alert("Request Failed.");
                details_giohang_tbl.removeClass('ajax-loading');
            },
            success: function (data) {
                details_giohang_tbl.removeClass('ajax-loading');
                redirect();
            }
        });
    });

    /** */
    $(document).on('change','input[name="number_qty_sp"]',function(e) {
        $('button[name="update_cart"]').prop('disabled', false);
    });

    /** */
    let frm_single_add_to_cart = $('.frm-single_add_to_cart');
    if (frm_single_add_to_cart.length > 0) {
        frm_single_add_to_cart.on("submit", function(e) {

            e.preventDefault();
            let btn_submit = $(this).find('.single_add_to_cart');
            let button_text = '<span>' + btn_submit.find('span').html() + '</span>';
            let button_text_arrow = "<i class=\"fa-light fa-arrow-right\"></i>";
            let button_text_loading = "<i class=\"fa-regular fa-spinner fa-spin-pulse\"></i>";

            $.ajax({
                context: this,
                type: 'POST',
                url: BASE_URL + 'page/ajax_single_add_to_cart',
                data: new FormData(this),
                mimeType: "multipart/form-data",
                contentType: false,
                cache: false,
                processData: false,
                beforeSend: function () {
                    btn_submit.prop('disabled', true).html(button_text + button_text_loading);
                    $(this).find(".btn_submit_addtocart").find('.added_to_cart').remove();
                },
                error: function () {
                    alert("Request Failed.");
                    btn_submit.prop('disabled', false).html(button_text + button_text_arrow);
                },
                success: function (data) {
                    btn_submit.prop('disabled', false).html(button_text + button_text_arrow);
                    $(this).find(".btn_submit_addtocart").append('<p class="added_to_cart" data-glyph="">' + hd.lang.added_to_cart + '<a class="view-cart-link" title="View cart" href="' + BASE_URL + 'gio-hang" data-glyph="">' + hd.lang.view_added_to_cart + '</a></p>');
                }
            });
        });
    }

    /** */
    let products_properties = $('.products-properties');
    products_properties.find('select.props').on('change', function() {
        let prop_id = $(this).val();
        $(this).closest('form').find(':submit').trigger('click');
    });

    /** */
    let reset_filter_btn = $('.reset-filter-btn');
    if (reset_filter_btn.length > 0) {
        reset_filter_btn.on('click', function(e) {
            let _get = $.query.REMOVE("props");
            redirect(window.location.href.split(/[?#]/)[0] + _get.toString());
        });
    }

    /** */
    let frm_checkout = $('form#frm-checkout');
    frm_checkout.on("submit", function(e) {
        e.preventDefault();

        //...
        let hoten = $('.frm-checkout input[name="hoten"]').val();
        let email = $('.frm-checkout input[name="email"]').val();
        let sodienthoai = $('.frm-checkout input[name="sodienthoai"]').val();
        let thanhpho = $('.frm-checkout select[name="thanhpho"]').val();
        let quanhuyen = $('.frm-checkout input[name="quanhuyen"]').val();
        let diachi = $('.frm-checkout input[name="diachi"]').val();
        let ghichu = $('.frm-checkout textarea[name="ghichu"]').val();

        // ajax post form
        let btn_submit = $(this).find(':submit');
        let button_text = '<span>' + btn_submit.find('span').html() + '</span>';
        let button_text_arrow = "<i class=\"fa-light fa-arrow-right\"></i>";
        let button_text_loading = "<i class=\"fa-regular fa-spinner fa-spin-pulse\"></i>";

        //...
        let tt_khachhang = new Array();
        let vl_khachhang = new Array();
        let tt_sanpham = $('input[name="tt_sanpham"]').val();
        let type_thanhtoan = $('input[name="payment_method"]:checked').val();
        $('form[name="frm-checkout"] :input:not([disabled])').each(function() {
            let _tmp = $('label[for=' + $(this).attr('name') + ']').text().replace('*', '').trim();
            tt_khachhang.push(_tmp);
            vl_khachhang.push($(this).val());
        });

        let user_id = $('input[name="user_id"]').val();
        let totals = $('input[name="totals"]').val();
        let pointsx = $('input[name="pointsx"]').val();

        $.ajax({
            context: this,
            type: 'POST',
            url: BASE_URL + 'page/ajax_insert_donhang',
            data:{
                'tt_khachhang': tt_khachhang,
                'vl_khachhang': vl_khachhang,
                'tt_sanpham': tt_sanpham,
                'type_thanhtoan': type_thanhtoan,
                'user_id': user_id,
                'email': email,
                'totals': totals,
                'pointsx': pointsx,
            },
            beforeSend: function () {
                $(this).find(':submit').prop('disabled', true).html(button_text + button_text_loading);
            },
            error: function () {
                alert("Request Failed.");
                $(this).find(':submit').prop('disabled', false).html(button_text + button_text_arrow);
            },
            success: function (data) {
                $(this).find(':submit').prop('disabled', false).html(button_text + button_text_arrow);
                redirect(data);
            }
        });
    });

    /** contact form */
    let frm_contact = $('form#frm-contact');
    frm_contact.on("submit", function(e) {
        e.preventDefault();

        // ajax post form
        let btn_submit = $(this).find(':submit');
        let button_text = '<span>' + btn_submit.find('span').html() + '</span>';
        let button_text_arrow = "<i class=\"fa-light fa-arrow-right\"></i>";
        let button_text_loading = "<i class=\"fa-regular fa-spinner fa-spin-pulse\"></i>";

        $.ajax({
            context: this,
            type: 'POST',
            url: BASE_URL + 'page/ajax_contactform',
            data: new FormData(this),
            mimeType: "multipart/form-data",
            contentType: false,
            cache: false,
            processData: false,
            beforeSend: function () {
                $(this).find(':submit').prop('disabled', true).html(button_text + button_text_loading);
            },
            error: function () {
                alert("Request Failed.");
                $(this).find(':submit').prop('disabled', false).html(button_text + button_text_arrow);
            },
            success: function (data) {
                $(this).find(':submit').prop('disabled', false).html(button_text + button_text_arrow);
                let json = JSON.parse(data);
                if ($.trim(json.error) !== '0') {
                    $(this).find('.message').addClass('error');
                } else {
                    $(this)[0].reset();
                }

                $(this).find('.message').removeClass('hide').html(json.mess);
            }
        });

        return false;
    });

    /** multiselect*/
    let multiselect = $(".multi-select");
    multiselect.each(function() {
        $(this).multiselect({
            texts: {
                placeholder: this.title, // or $(this).prop('title'),
            },
        });
    });

    /** language */
    const lang_box_frm = $(".lang-box-frm");
    lang_box_frm.find('a.lang').on('click', function (e) {
        let lang = $(this).data('lg');
        let uri = $(this).data('href');
        $.ajax({
            type: 'POST',
            url: BASE_URL + 'users/set_lang',
            data: {
                'lang': lang,
                'uri': uri,
            },
            success: function (result) {
                if (lang != 'vi') {
                    if (result == '/') result = '';
                    result = result + '?lg=' + lang;
                }
                redirect(BASE_URL + result);
            }
        });
    });

    /**qty*/
    $('.input-number-increment').on('click', function (e) {
        e.preventDefault();
        let $input = $(this).parents('.input-number-group').find('#qty');
        let val = parseInt($input.val(), 10);
        $input.val(val + 1);
    });
    $('.input-number-decrement').on('click', function (e) {
        e.preventDefault();
        let $input = $(this).parents('.input-number-group').find('#qty');
        let val = parseInt($input.val(), 10);
        if (val > 1) {
            $input.val(val - 1);
        }
    });

    /** Remove empty P tags created by WP inside of Accordion and Orbit */
    $('.accordion p:empty, .orbit p:empty').remove();

    /** Adds Flex Video to YouTube and Vimeo Embeds */
    $('iframe[src*="youtube.com"], iframe[src*="vimeo.com"]').each(() => {
        if ($(this).innerWidth() / $(this).innerHeight() > 1.5) {
            $(this).wrap("<div class='widescreen responsive-embed'/>");
        } else {
            $(this).wrap("<div class='responsive-embed'/>");
        }
    });

    /**tabs + cookie*/
    const _tabs_wrapper = $(".w-filter-tabs");
    _tabs_wrapper.each((index, el) => {
        const _rand = nanoid(6);
        $(el).addClass(_rand);

        const _tabs = $(el).find(".filter-tabs");
        const _tabs_content = $(el).find(".filter-tabs-content");
        _tabs_content.find('.tabs-panel').hide();
        let _hash = _tabs.find('a:first').attr("href");
        if (getCookie('lastTab_' + index) === '' || getCookie('lastTab_' + index) === 'undefined') {
            setCookie('lastTab_' + index, _hash, 100);
        }
        _tabs.find('a[href="' + getCookie('lastTab_' + index) + '"]').addClass("current");
        _tabs.find('a').on("click", function (e) {
            e.preventDefault();
            let _hash = $(this).attr("href");
            setCookie('lastTab_' + index, _hash, 100);
            _tabs.find('a.current').removeClass("current");
            _tabs_content.find('.tabs-panel:visible').hide();
            $(this.hash).fadeIn();
            $(this).addClass("current");
        }).filter(".current").trigger('click');
    });

    /** toggle menu */
    const _toggle_menu = $(".toggle_menu");
    _toggle_menu.find("li.is-active.has-submenu-toggle").find(".submenu-toggle").trigger('click');
});

/**
 * DOMContentLoaded
 */
document.addEventListener( 'DOMContentLoaded', () => {

    /**attribute target="_blank" is not W3C compliant*/
    const _blanks = [...document.querySelectorAll('a._blank, a.blank, a[target="_blank"]')];
    Array.prototype.forEach.call(_blanks, (el) => {
        el.removeAttribute('target');
        el.setAttribute('target', '_blank');
        if (!1 === el.hasAttribute('rel')) {
            el.setAttribute('rel', 'noopener noreferrer nofollow');
        }
    });

    /**remove style img tag*/
    const _img = document.querySelectorAll('img');
    Array.prototype.forEach.call(_img, (el) => {
        el.removeAttribute('style');
    });
});

/** vars */
const getParameters = (URL) => JSON.parse('{"' + decodeURI(URL.split("?")[1]).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');
const touchSupported = () => {
    ('ontouchstart' in window || window.DocumentTouch && document instanceof window.DocumentTouch);
};

/**
 * @param cname
 * @returns {unknown}
 */
const getCookie = (cname) => (
    document.cookie.match('(^|;)\\s*' + cname + '\\s*=\\s*([^;]+)')?.pop() || ''
)

/**
 * @param cname
 * @param cvalue
 * @param exdays
 */
function setCookie(cname, cvalue, exdays) {
    let d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + "; " + expires;
}

/**
 * @param url
 * @param $delay
 */
function redirect(url = null, $delay = 10) {
    setTimeout(function () {
        if (url === null || url === '' || typeof url === "undefined") {
            document.location.assign(window.location.href);
        } else {
            url = url.replace(/\s+/g, '');
            document.location.assign(url);
        }
    }, $delay);
}

/**
 * @param name
 * @returns {string}
 */
function query_string(name) {
    let _name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    let regex = new RegExp("[\\?&]" + _name + "=([^&#]*)"),
        results = regex.exec(location.search);

    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}

/**
 * @param page
 * @param title
 * @param url
 */
function pushState(page, title, url) {
    if ("undefined" !== typeof history.pushState) {
        history.pushState({page: page}, title, url);
    } else {
        window.location.assign(url);
    }
}

/** */
/** import Swiper bundle with all modules installed */
import { Swiper } from 'swiper/bundle';

/** swiper container */
const _swiper_container = [...document.querySelectorAll('.w-swiper')];
_swiper_container.forEach((el, index) => {
    const _rand = nanoid(12),
        _class = 'swiper-' + _rand,
        _next_class = 'next-' + _rand,
        _prev_class = 'prev-' + _rand,
        _pagination_class = 'pagination-' + _rand,
        _scrollbar_class = 'scrollbar-' + _rand;

    el.classList.add(_class);

    /** swiper controls */
    let _controls = el.closest('.swiper-section').querySelector('.swiper-controls');
    if (_controls == null) {
        _controls = document.createElement("div");
        _controls.classList.add('swiper-controls');
        el.after(_controls);
    }

    /** swiper options */
    const el_swiper_wrapper = el.querySelector('.swiper-wrapper');
    let _obj_options = JSON.parse(el_swiper_wrapper.dataset.options);

    if (isEmpty(_obj_options)) {
        _obj_options = {
            "autoview": "true",
            "loop": "true",
            "autoplay": "true",
            "navigation": "true"
        };
    }

    /** init options*/
    let _result_options = {};

    _result_options.grabCursor = !0;
    _result_options.allowTouchMove = !0;
    _result_options.threshold = 0.5;
    _result_options.hashNavigation = !1;

    /** responsive view*/
    let _desktop_data = '1',
        _tablet_data = '1',
        _mobile_data = '1';

    if ("desktop" in _obj_options) {
        _desktop_data = _obj_options.desktop;
    }

    if ("tablet" in _obj_options) {
        _tablet_data = _obj_options.tablet;
    }

    if ("mobile" in _obj_options) {
        _mobile_data = _obj_options.mobile;
    }

    /** gap*/
    if ("gap" in _obj_options) {
        _result_options.spaceBetween = 20;
    }

    /** autoview */
    if ("autoview" in _obj_options) {
        _result_options.slidesPerView = 'auto';
        _result_options.loopedSlides = 12;
        if ("gap" in _obj_options) {
            _result_options.breakpoints = {
                640: { spaceBetween: 30 }
            };
        }
    } else {
        _result_options.slidesPerView = parseInt(_mobile_data);
        if ("gap" in _obj_options) {
            _result_options.breakpoints = {
                640: {
                    spaceBetween: 30,
                    slidesPerView: parseInt(_tablet_data)
                },
                1024: {
                    spaceBetween: 30,
                    slidesPerView: parseInt(_desktop_data)
                },
            };
        } else {
            _result_options.breakpoints = {
                640: { slidesPerView: parseInt(_tablet_data) },
                1024: { slidesPerView: parseInt(_desktop_data) },
            };
        }
    }

    if ("autoview" in _obj_options || _result_options.slidesPerView > 1) {
        _result_options.watchSlidesVisibility = !0;
    }

    /** centered*/
    if ("centered" in _obj_options) {
        _result_options.centeredSlides = !0;
    }

    /** speed*/
    if ("speed" in _obj_options) {
        _result_options.speed = parseInt(_obj_options.speed);
    } else {
        _result_options.speed = random(600, 1200);
    }

    /** observer*/
    if ("observer" in _obj_options) {
        _result_options.observer = !0;
        _result_options.observeParents = !0;
    }

    /** group*/
    if ("group" in _obj_options && !("autoview" in _obj_options)) {
        _result_options.slidesPerGroupSkip = !0;
        _result_options.loopFillGroupWithBlank = !0;
        _result_options.slidesPerGroup = parseInt(_obj_options.group);
    }

    /** fade*/
    if ("fade" in _obj_options) {
        _result_options.effect = 'fade';
        _result_options.fadeEffect = { crossFade: !0 };
    }

    /** autoheight*/
    if ("autoheight" in _obj_options) {
        _result_options.autoHeight = !0;
    }

    /** freemode*/
    if ("freemode" in _obj_options) {
        _result_options.freeMode = !0;
    }

    /** loop*/
    if ("loop" in _obj_options && !("row" in _obj_options)) {
        _result_options.loop = !0;
        _result_options.loopFillGroupWithBlank = !0;
    }

    /** autoplay*/
    if ("autoplay" in _obj_options) {
        if ("delay" in _obj_options) {
            _result_options.autoplay = {
                disableOnInteraction: !1,
                delay: parseInt(_obj_options.delay),
            };
        } else {
            _result_options.autoplay = {
                disableOnInteraction: !1,
                delay: random(5500, 6500),
            };
        }
        if ("reverse" in _obj_options) {
            _result_options.reverseDirection = !0;
        }
    }

    /** row*/
    if ("row" in _obj_options) {
        _result_options.direction = 'horizontal';
        _result_options.loop = !1;
        _result_options.grid = {
            rows: parseInt(_obj_options.row),
            fill: 'row',
        };
    }

    /**navigation*/
    if ("navigation" in _obj_options) {
        const _section = el.closest('.swiper-section');
        let _btn_prev = _section.querySelector('.swiper-button-prev');
        let _btn_next = _section.querySelector('.swiper-button-next');

        if (_btn_prev && _btn_next) {
            _btn_prev.classList.add(_prev_class);
            _btn_next.classList.add(_next_class);
        } else {
            _btn_prev = document.createElement("div");
            _btn_next = document.createElement("div");

            _btn_prev.classList.add('swiper-button', 'swiper-button-prev', _prev_class);
            _btn_next.classList.add('swiper-button', 'swiper-button-next', _next_class);

            _controls.appendChild(_btn_prev);
            _controls.appendChild(_btn_next);

            //_btn_prev.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="currentColor"><g><path d="M0,0h24v24H0V0z" fill="none"/></g><g><polygon points="6.23,20.23 8,22 18,12 8,2 6.23,3.77 14.46,12"/></g></svg>';
            //_btn_next.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="currentColor"><g><path d="M0,0h24v24H0V0z" fill="none"/></g><g><polygon points="6.23,20.23 8,22 18,12 8,2 6.23,3.77 14.46,12"/></g></svg>';
            _btn_prev.setAttribute("data-glyph-fa", "");
            _btn_next.setAttribute("data-glyph-fa", "");
        }

        _result_options.navigation = {
            nextEl: '.' + _next_class,
            prevEl: '.' + _prev_class,
        };
    }

    /** pagination */
    if ("pagination" in _obj_options) {
        let _swiper_pagination = document.createElement("div");
        _swiper_pagination.classList.add('swiper-pagination', _pagination_class);
        _controls.appendChild(_swiper_pagination);

        if (_obj_options.pagination === 'fraction') {
            _result_options.pagination = {
                el: '.' + _pagination_class,
                type: 'fraction',
            };
        } else if (_obj_options.pagination === 'progressbar') {
            _result_options.pagination = {
                el: '.' + _pagination_class,
                type: "progressbar",
            };
        } else if (_obj_options.pagination === 'dynamic') {
            _result_options.pagination = {
                dynamicBullets: !0,
                el: '.' + _pagination_class,
            };
        } else {
            _result_options.pagination = {
                dynamicBullets: !1,
                el: '.' + _pagination_class,
            };
        }

        _result_options.pagination.clickable = !0;
    }

    /** scrollbar */
    if ("scrollbar" in _obj_options) {
        let _swiper_scrollbar = document.createElement("div");
        _swiper_scrollbar.classList.add('swiper-scrollbar', _scrollbar_class);
        _controls.appendChild(_swiper_scrollbar);
        _result_options.scrollbar = {
            hide: !0,
            el: '.' + _scrollbar_class,
        };
    }

    /** vertical*/
    if ("vertical" in _obj_options) {
        _result_options.direction = 'vertical';
    }

    /**parallax*/
    if ("parallax" in _obj_options) {
        _result_options.parallax = !0;
    }

    /**_marquee**/
    if ("marquee" in _obj_options) {
        _result_options.centeredSlides = !0;
        _result_options.autoplay = {
            delay: 1,
            disableOnInteraction: !1
        };
        _result_options.loop = !0;
        _result_options.allowTouchMove = !0;
    }

    /**progress*/
    if ("progressbar" in _obj_options) {
        let _swiper_progress = document.createElement("div");
        _swiper_progress.classList.add('swiper-progress');
        _result_options.appendChild(_swiper_progress);
    }

    /**cssMode*/
    if (!("row" in _obj_options)
        && !("marquee" in _obj_options)
        && !("centered" in _obj_options)
        && !("freemode" in _obj_options)
        && !("progressbar" in _obj_options)
        && is_mobile()
        && !el.classList.contains('sync-swiper')) {
        _result_options.cssMode = !0; /* API CSS Scroll Snap */
    }

    /** progress dom*/
    let _swiper_progress = _controls.querySelector('.swiper-progress');

    /** init*/
    _result_options.on = {
        init: function () {
            let t = this;
            if ("parallax" in _obj_options) {
                t.autoplay.stop();
                t.touchEventsData.formElements = "*";
                const parallax = el.querySelectorAll('.--bg');
                [].slice.call(parallax).map((elem) => {
                    let p = elem.dataset.swiperParallax.replace("%", "");
                    if (!p) {
                        p = 95;
                    }
                    elem.dataset.swiperParallax = toString(p / 100 * t.width);
                });
            }

            if ("progressbar" in _obj_options) {
                _swiper_progress.classList.add('progress');
            }
        },

        slideChange: function () {
            if ("progressbar" in _obj_options) {
                _swiper_progress.classList.remove('progress');
            }

            /** sync*/
            let t = this;
            if (el.classList.contains('sync-swiper')) {
                const el_closest = el.closest('section.section');
                const sync_swipers = Array.from(el_closest.querySelectorAll('.sync-swiper:not(.sync-exclude)'));
                sync_swipers.forEach((item, i) => {
                    let _local_swiper = item.swiper;
                    if ("loop" in _obj_options) {
                        _local_swiper.slideToLoop(t.activeIndex, parseInt(_obj_options.speed), true);
                    } else {
                        _local_swiper.slideTo(t.activeIndex, parseInt(_obj_options.speed), true);
                    }
                });
            }
        },

        slideChangeTransitionEnd: function () {
            if ("progressbar" in _obj_options) {
                _swiper_progress.classList.add('progress');
            }
        }
    };

    /**console.log(_obj_options);*/
    let _swiper = new Swiper('.' + _class, _result_options);
    if (!("autoplay" in _obj_options) && !("marquee" in _obj_options)) {
        _swiper.autoplay.stop();
    }

    /** now add mouseover and mouseout events to pause and resume the autoplay;*/
    el.addEventListener('mouseover', () => {
        _swiper.autoplay.stop();
    });
    el.addEventListener('mouseout', () => {
        if ("autoplay" in _obj_options) {
            _swiper.autoplay.start();
        }
    });
});